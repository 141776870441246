<template>
   <AppLayout>
      <template #main>
         <FeaturesSection :simple-blocks="simpleBlocks" />

         <LatestNews
            v-if="latestPosts.data.length > 0"
            :latest-posts="latestPosts.data"
         />

         <div class="mx-auto max-w-7xl px-6 lg:px-8 sm:py-20">
            <div class="mx-auto max-w-2xl text-center">
               <h2
                  class="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl"
               >
                  {{ $t('home.youtube') }}
               </h2>
            </div>
            <div
               class="mx-auto  grid max-w-2xl auto-rows-fr grid-cols-1 gap-8 mt-16 sm:mt-20 lg:mx-0 lg:max-w-none lg:grid-cols-2"
            >
               <YoutubeVideo
                  v-motion
                  v-for="(link, key) in linkBlocks"
                  :key="key"
                  :initial="{ opacity: 0, y: 100 }"
                  :visible-once="{ opacity: 1, y: 0 }"
                  :delay="200 + 100 * key"
                  :url="link.youtube"
               />
            </div>
         </div>

         <div class="grid py-24 sm:py-32">
            <div class="col-start-1 row-start-1">
               <SponsorsSection />
            </div>
         </div>
      </template>
   </AppLayout>
</template>

<script setup>
   import AppLayout from '@/Layouts/AppLayout.vue';
   import LatestNews from '@/Components/Pages/Home/LatestNewsSection.vue';
   import YoutubeVideo from '@/Components/YoutubeVideo.vue';
   import { computed } from 'vue';
   import SponsorsSection from '@/Components/SponsorsSection.vue';
   import FeaturesSection from '@/Components/Pages/Home/FeaturesSection.vue';
   
   import {map, property} from 'lodash';
   const props = defineProps({
      latestPosts: Object,
      pageBlocks: Object,
   });

   const simpleBlocks = computed(() => {
      return props.pageBlocks.simple.data;
   });

   const linkBlocks = computed(() => {
      return map(props.pageBlocks.links.data, property('links'));
   });
</script>

<style scoped>

</style>
