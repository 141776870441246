<template>
   <div>
      <button
         type="button"
         @click.prevent="toggleMenu"
         class="inline-flex items-center px-3 py-2 text-sm leading-4 font-medium text-gray-500 hover:text-primary-color hover-animation"
      >
         <UserCircleIcon class="h-7" />
      </button>
      <Menu
         ref="menu"
         id="overlay_menu"
         :popup="true"
         :model="[
            ...isEmpty(page.props.auth.user) ? [] : [
               {
                  label: $t('account.profile'),
                  command: () => {
                     router.visit(route('profile.edit'));
                  },
               },
            ],
            ...isEmpty(page.props.auth.user) ? [] : [
               {
                  label: $t('account.logout'),
                  command: () => {
                     router.post(route('logout'));
                     toggleMenu();
                  },
               },
            ],
            ...!isEmpty(page.props.auth.user) ? [] : [
               {
                  label: $t('account.login'),
                  command: () => {
                     router.visit(route('login'));
                  },
               },
            ],
            ...!isEmpty(page.props.auth.user) ? [] : [
               {
                  label: $t('account.register'),
                  command: () => {
                     router.visit(route('register'));
                  },
               },
            ],
         ]"
         class="!text-sm"
      />
   </div>
</template>

<script setup>
   import { UserCircleIcon } from '@heroicons/vue/solid';
   import { isEmpty } from 'lodash';
   import { router, usePage } from '@inertiajs/vue3';
   import { ref } from 'vue';
   import { Menu } from 'primevue';

   const page = usePage();

   const menu = ref();
   const toggleMenu = (event) => {
      menu.value.toggle(event);
   };
   
</script>

<style scoped></style>
