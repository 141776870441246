<template>
   <div class="sticky top-0 z-30 shadow-md bg-[#E5E7EB] bg-[url('/images/header_background.png')] bg-cover">
      <nav class="max-w-8xl mx-auto sm:px-6 lg:px-8 shadow-md">
         <!-- Primary Navigation Menu -->
         <div class="mx-auto px-4 sm:px-6 lg:px-8">
            <div
               class="flex items-center justify-between lg:justify-evenly"
            >
               <!-- Logo -->
               <div class="shrink">
                  <Link :href="route('home.index')">
                     <span class="sr-only">{{
                        $page.props.app_name
                     }}</span>
                     <img
                        role="presentation"
                        class="h-20 w-20"
                        src="/images/logos/gravity_cy_logo.png"
                        alt
                     >
                  </Link>
               </div>

               <!-- Navigation Links -->
               <div
                  class="hidden lg:flex flex-grow justify-center space-x-12 sm:-my-px sm:ms-10"
               >
                  <NavLink
                     v-for="item in menuPages"
                     :key="item.slug"
                     :href="route(item.index_route)"
                     class="flex shrink items-center text-lg font-medium hover-animation hover:text-primary-color"
                     :active="route().current(item.index_route)"
                  >
                     {{ item.title }}
                  </NavLink>
               </div>

               <LocaleDropdown class="shrink items-end hidden lg:flex" />

               <AccountDropdown class="shrink items-end hidden lg:flex" />


               <!-- Hamburger -->
               <div class="-me-2 flex items-center lg:hidden">
                  <MobileNavigationMenu :menu-pages="menuPages" />
               </div>
            </div>
         </div>
      </nav>
   </div>
</template>

<script setup>
   import { Link, usePage } from '@inertiajs/vue3';

   import LocaleDropdown from '@/Components/Layout/LocaleDropdown.vue';
   import NavLink from '@/Components/NavLink.vue';
   import { computed } from 'vue';
   import { filter } from 'lodash';
   import AccountDropdown from '@/Components/AccountDropdown.vue';
   import MobileNavigationMenu from '@/Components/Layout/MobileNavigationMenu.vue';
   import ApplicationLogo from '@/Components/ApplicationLogo.vue';

   const page = usePage();

   const menuPages = computed(() => {
      return filter(page.props.pages.data, (page) => {
         return parseInt(page.show_in_menu) === 1;
      });
   });
</script>
