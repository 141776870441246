<template>
   <div v-bind="$attrs">
      <button
         type="button"
         @click.prevent="toggleMenu"
         class="inline-flex items-center px-3 py-2 border border-transparent bg-white bg-opacity-0 space-x-2 text-sm leading-4 font-medium rounded-md hover:text-primary-color focus:outline-none transition ease-in-out duration-150"
      >
         <span
            :class="`fi fi-${$page.props.locale === 'en' ? 'gb' : 'gr'}`"
         />
         <p>{{ $t(`misc.language.${$page.props.locale}`) }}</p>
      </button>
      <Menu
         ref="menu"
         id="overlay_menu"
         :popup="true"
         :model="[
            {
               label: $t('misc.language.en'),
               command: () => {
                  router.post(route('locale', {locale: 'en'}));
               },
            },
            {
               label: $t('misc.language.el'),
               command: () => {
                  router.post(route('locale', {locale: 'el'}));
               },
            },
         ]"
         class="!text-sm"
      />
   </div>
</template>

<script setup>
   import { router } from '@inertiajs/vue3';
   import { ref } from 'vue';
   import { Menu } from 'primevue';

   const menu = ref();
   const toggleMenu = (event) => {
      menu.value.toggle(event);
   };
</script>

<style scoped></style>
