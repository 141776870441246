<template>
   <div class="card flex justify-center">
      <Drawer
         v-model:visible="visible"
         class="!bg-[#E5E7EB]"
         position="right"
      >
         <template #container="{ closeCallback }">
            <div class="flex flex-col h-full">
               <div class="flex items-end justify-end p-2 ">
                  <span class="items-end gap-2">
                     <Button
                        size="small"
                        type="button"
                        variant="text"
                        @click="closeCallback"
                        icon="pi pi-times"
                        rounded
                        outlined
                     />
                  </span>
               </div>
               <div class="bg-[#E5E7EB] pt-2 pb-3 space-y-1 overflow-y-auto">
                  <Button
                     unstyled
                     v-for="appPage in menuPages"
                     :key="appPage.slug"
                     @click="() => {
                        closeCallback();
                        router.visit(route(appPage.index_route));
                     }"
                     :href="route(appPage.index_route)"
                     :active="route().current(appPage.index_route)"
                     class="flex shrink items-center text-lg font-medium hover-animation hover:text-primary-color"
                     :class="route().current(appPage.index_route) ? 'block w-full ps-3 pe-4 py-2 border-l-4 border-primary-color-dark text-start text-base font-medium text-primary-color-dark bg-white focus:outline-none focus:primary-color-dark focus:primary-color-dark hover-animation' : 'block w-full ps-3 pe-4 py-2 border-l-4 border-transparent text-start text-base font-medium text-gray-600 hover:text-gray-800 hover:bg-gray-50 hover:border-gray-300 focus:outline-none focus:text-gray-800 focus:bg-gray-50 focus:border-gray-300 hover-animation'"
                  >
                     {{ appPage.title }}
                  </Button>
                  <div class="px-4">
                     <div class="text-base py-2 font-medium text-gray-800">
                        {{
                           $page.props.auth.user
                              ? $t('account.my_account')
                              : $t('account.account')
                        }}
                     </div>
                     <Button
                        unstyled
                        v-if="$page.props.auth.user"
                        :class="route().current('profile.edit') ? 'block w-full ps-3 pe-4 py-2 border-l-4 border-primary-color-dark text-start text-base font-medium text-primary-color-dark bg-white focus:outline-none focus:primary-color-dark focus:primary-color-dark hover-animation' : 'block w-full ps-3 pe-4 py-2 border-l-4 border-transparent text-start text-base font-medium text-gray-600 hover:text-gray-800 hover:bg-gray-50 hover:border-gray-300 focus:outline-none focus:text-gray-800 focus:bg-gray-50 focus:border-gray-300 hover-animation'"
                        @click="() => {
                           closeCallback();
                           if (route().current('profile.edit')) {
                              return;
                           }
                           router.visit(route('profile.edit'))
                        }"
                     >
                        {{ $t('account.profile') }}
                     </Button>
                     <Button
                        unstyled
                        v-if="$page.props.auth.user"
                        :class="'block w-full ps-3 pe-4 py-2 border-l-4 border-transparent text-start text-base font-medium text-gray-600 hover:text-gray-800 hover:bg-gray-50 hover:border-gray-300 focus:outline-none focus:text-gray-800 focus:bg-gray-50 focus:border-gray-300 hover-animation'"
                        @click="() => {
                           closeCallback();

                           router.post(route('logout'))
                        }"
                     >
                        {{ $t('account.logout') }}
                     </Button>

                     <Button
                        unstyled
                        v-if="$page.props.auth.user === null"
                        :class="route().current('login') ? 'block w-full ps-3 pe-4 py-2 border-l-4 border-primary-color-dark text-start text-base font-medium text-primary-color-dark bg-white focus:outline-none focus:primary-color-dark focus:primary-color-dark hover-animation' : 'block w-full ps-3 pe-4 py-2 border-l-4 border-transparent text-start text-base font-medium text-gray-600 hover:text-gray-800 hover:bg-gray-50 hover:border-gray-300 focus:outline-none focus:text-gray-800 focus:bg-gray-50 focus:border-gray-300 hover-animation'"
                        @click="() => {
                           closeCallback();
                           if (route().current('login')) {
                              return;
                           }
                           router.visit(route('login'))
                        }"
                     >
                        {{ $t('account.login') }}
                     </Button>
                     <Button
                        unstyled
                        v-if="$page.props.auth.user === null"
                        :class="route().current('register') ? 'block w-full ps-3 pe-4 py-2 border-l-4 border-primary-color-dark text-start text-base font-medium text-primary-color-dark bg-white focus:outline-none focus:primary-color-dark focus:primary-color-dark hover-animation' : 'block w-full ps-3 pe-4 py-2 border-l-4 border-transparent text-start text-base font-medium text-gray-600 hover:text-gray-800 hover:bg-gray-50 hover:border-gray-300 focus:outline-none focus:text-gray-800 focus:bg-gray-50 focus:border-gray-300 hover-animation'"
                        @click="() => {
                           closeCallback();
                           if (route().current('register')) {
                              return;
                           }
                           router.visit(route('register'))
                        }"
                     >
                        {{ $t('account.register') }}
                     </Button>
                  </div>
                  <div class="px-4">
                     <div class="text-base py-2 font-medium text-gray-800">
                        {{ $t('misc.language.name') }}
                     </div>
                     <Button
                        unstyled
                        :class="page.props.locale === 'en' ? 'block w-full ps-3 pe-4 py-2 border-l-4 border-primary-color-dark text-start text-base font-medium text-primary-color-dark bg-white focus:outline-none focus:primary-color-dark focus:primary-color-dark hover-animation' : 'block w-full ps-3 pe-4 py-2 border-l-4 border-transparent text-start text-base font-medium text-gray-600 hover:text-gray-800 hover:bg-gray-50 hover:border-gray-300 focus:outline-none focus:text-gray-800 focus:bg-gray-50 focus:border-gray-300 hover-animation'"
                        @click="() => {
                           closeCallback();
                           if (page.props.locale === 'en') {
                              return;
                           }
                           router.post(route('locale', { locale: 'en' }))
                        }"
                     >
                        <span class="fi fi-gb" />
                        {{ $t('misc.language.en') }}
                     </Button>
                     <Button
                        unstyled
                        method="post"
                        :class="page.props.locale === 'el' ? 'block w-full ps-3 pe-4 py-2 border-l-4 border-primary-color-dark text-start text-base font-medium text-primary-color-dark bg-white focus:outline-none focus:primary-color-dark focus:primary-color-dark hover-animation' : 'block w-full ps-3 pe-4 py-2 border-l-4 border-transparent text-start text-base font-medium text-gray-600 hover:text-gray-800 hover:bg-gray-50 hover:border-gray-300 focus:outline-none focus:text-gray-800 focus:bg-gray-50 focus:border-gray-300 hover-animation'"
                        @click="() => {
                           closeCallback();
                           if (page.props.locale === 'el') {
                              return;
                           }
                           router.post(route('locale', { locale: 'el' }))
                        }"
                     >
                        <span class="fi fi-gr" />
                        {{ $t('misc.language.el') }}
                     </Button>
                  </div>
               </div>
            </div>
         </template>
      </Drawer>
      <Button
         icon="pi pi-bars"
         severity="contrast"
         variant="text"
         size="large"
         @click="visible = true"
      />
   </div>
</template>

<script setup>
   import Drawer from 'primevue/drawer';
   import { Button } from 'primevue';
   import { ref } from 'vue';
   import { router, usePage } from '@inertiajs/vue3';

   const page = usePage();
   const visible = ref(false);


   defineProps({
      menuPages: Object,
   });

</script>


<style scoped>

</style>
