<template>
   <div class="min-h-screen">
      <slot name="head">
         <AppHead
            :title="currentHeadTitle"
            :description="$page.props.pageBlocks.banner.data.subtitle"
            :image="$page.props.pageBlocks.banner.data.media.original_url"
         />
      </slot>
      <AppNavigationMenu />

      <PreviewBanner
         v-if="$page.props.is_preview"
      />
      <header v-if="showBanner">
         <PageBanner v-motion-fade-visible-once />
      </header>

      <main>
         <slot name="main" />
      </main>

      <AppFooter
         v-if="showFooter"
         :pages="page.props.pages"
      />
      <DynamicDialog />
      <ConfirmationDialog />
      <ToastNotifications />
      <ScrollTop behavior="smooth" />
   </div>
</template>

<script setup>
   import { usePage } from '@inertiajs/vue3';
   import AppFooter from '@/Components/Layout/AppFooter.vue';
   import AppNavigationMenu from '@/Components/Layout/AppNavigationMenu.vue';
   import { find, head, isEmpty, split } from 'lodash';
   import { computed } from 'vue';
   import PageBanner from '@/Components/Pages/Home/PageBanner.vue';
   import PreviewBanner from '@/Components/Banners/PreviewBanner.vue';
   import AppHead from '@/Components/Layout/AppHead.vue';
   import DynamicDialog from 'primevue/dynamicdialog';
   import ScrollTop from 'primevue/scrolltop';
   import ConfirmationDialog from '@/Components/Layout/ConfirmationDialog.vue';
   import ToastNotifications from '@/Components/ToastNotifications.vue';

   const page = usePage();

   const props = defineProps({
      headTitle: {
         type: String,
         required: false,
      },
      showBanner: {
         type: Boolean,
         default: true,
      },
   });

   const showFooter = computed(() => {
      return true;
   });

   const currentPage = find(page.props.pages.data, function (item) {
      const currentRoute = route().current();
      const currentSlug = head(split(currentRoute, '.'));
      if (isEmpty(currentSlug)) {
         return route().current() === item.index_route;
      }
      return currentSlug === item.slug.replace('-', '_');
   });

   const currentHeadTitle = computed(() => {
      if (isEmpty(props.headTitle)) {
         return currentPage?.title;
      }
      if (isEmpty(currentPage)) {
         return `${props.headTitle}`;
      }
      return `${currentPage?.title} - ${props.headTitle}`;
   });
</script>

<style scoped></style>
